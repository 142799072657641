import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "@emotion/styled"

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 4rem 2rem;
`

const HeroCard = styled.div`
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.98) 0%,
    rgba(255, 255, 255, 0.95) 100%
  );
  backdrop-filter: blur(20px);
  border-radius: 24px;
  padding: 4rem;
  margin: 2rem auto;
  max-width: 800px;
  box-shadow: 
    0 8px 32px rgba(0, 0, 0, 0.03),
    0 16px 64px rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.4);
  transition: transform 0.3s ease;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
      radial-gradient(circle at 0% 0%, rgba(34, 197, 94, 0.03) 0%, transparent 50%),
      radial-gradient(circle at 100% 0%, rgba(22, 163, 74, 0.04) 0%, transparent 50%),
      radial-gradient(circle at 100% 100%, rgba(21, 128, 61, 0.03) 0%, transparent 50%),
      radial-gradient(circle at 0% 100%, rgba(20, 83, 45, 0.03) 0%, transparent 50%);
    z-index: -1;
  }
`

const ProfileSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 2.5rem;
`

const ImageWrapper = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: 1rem;
  
  .bio-avatar {
    border-radius: 50%;
    box-shadow: 
      0 8px 24px rgba(0, 0, 0, 0.1),
      0 0 0 2px rgba(255, 255, 255, 0.8);
    width: 200px;
    height: 200px;
    object-fit: cover;
  }

  &:after {
    content: '';
    position: absolute;
    top: -8px;
    left: -8px;
    right: -8px;
    bottom: -8px;
    border-radius: 50%;
    background: linear-gradient(45deg, 
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.2)
    );
    z-index: -1;
    filter: blur(12px);
  }
`

const Bio = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 600px;
  margin: 0 auto;
  font-size: 1.125rem;
  line-height: 1.8;
  color: #374151;

  p {
    margin: 0;
    position: relative;
    padding: 0 1rem;
  }

  a {
    color: #059669;
    text-decoration: none;
    background: linear-gradient(to bottom, transparent 0%, transparent 60%, rgba(34, 197, 94, 0.1) 60%, rgba(34, 197, 94, 0.1) 100%);
    transition: all 0.3s ease;
    padding: 0.1rem 0.2rem;
    border-radius: 3px;

    &:hover {
      background: rgba(34, 197, 94, 0.1);
      color: #047857;
    }
  }
`

const Divider = styled.div`
  width: 80px;
  height: 3px;
  background: linear-gradient(90deg, 
    rgba(255, 255, 255, 0),
    rgba(34, 197, 94, 0.2),
    rgba(255, 255, 255, 0)
  );
  margin: 1rem auto;
  border-radius: 2px;
`

const Role = styled.h2`
  font-size: 1.5rem;
  font-weight: 500;
  color: #374151;
  margin: 0;
  letter-spacing: -0.02em;
`

const About = ({ location }) => {
  return (
    <Layout location={location} title="About">
      <Seo title="About - Phillip Michalowski" />
      <Content>
        <HeroCard>
          <ProfileSection>
            <ImageWrapper>
              <StaticImage
                className="bio-avatar"
                formats={["AUTO", "WEBP", "AVIF"]}
                src="../images/portrait.jpeg"
                width={200}
                height={200}
                quality={95}
                alt="Profile picture"
              />
            </ImageWrapper>
            <div>
              <Role>Senior Software Engineer</Role>
              <Divider />
            </div>
            <Bio>
              <p>
                My name is Phillip Michalowski and I'm a Senior Software Engineer based out
                of Boston, MA.
              </p>
              <p>
                I'm a purple belt in Brazilian Jiu-Jitsu (<a href="https://www.instagram.com/broadwayjiujitsu/?hl=en" target="_blank" rel="noopener noreferrer">Broadway Jiu Jitsu</a>), and I enjoy music production, violin, digital media,
                photography, learning languages, and writing.
              </p>
              <p>
                I'm broadly interested in education, information acquisition, and technology. 
              </p>
              <p>
                In my{" "}
                <a 
                  href="https://www.ezcater.com/company/about-us/" 
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  current work
                </a>
                , I build full-stack web applications using Next.js, TypeScript, and Ruby on Rails for a large e-commerce marketplace platform.
              </p>
            </Bio>
          </ProfileSection>
        </HeroCard>
      </Content>
    </Layout>
  )
}

export default About
